











































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import ImageViewer from '@/app/ui/components/ImageViewer/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon_alt2.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import controller, {
  UpdatePickupLogStatusPayload,
} from '@/app/ui/controllers/PickupRadiusValidationController'
import {
  HistoryLog,
  PickupRadiusValidationDetail,
} from '@/domain/entities/PickupRadiusValidation'
import {
  IHeaderCell,
  IPickupRadiusValidationDetailParameters,
  IPickupRadiusValidationDetailParams,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/pickupRadiusValidation'
import { EnumValidation } from '@/app/infrastructures/misc/Constants/pickupRadiusValidation'

@Component({
  components: {
    ImageViewer,
    RadioButton,
    TextInput,
    Button,
    LoadingOverlay,
    EditIcon,
    DataTableV2,
    PaginationNav,
  },
})
export default class PickupRadiusValidationDetailPage extends Vue {
  controller = controller

  enumValidation = EnumValidation
  isLoading = false
  showProofPhoto = false
  indexOfProofPhoto = 0
  proofPhoto: string[] = []

  detailData = {
    courierName: '-',
    courierPhoneNumber: '-',
    courierTypeValid: '-',
    courierLetterCode: '-',
    shipmentId: '-',
    createdAt: '-',
    actualPickupLocation: '-',
    pickupLocation: '-',
    address: '-',
    pickupRadius: 0,
  }

  validationValue: EnumValidation = EnumValidation.empty

  fictiveReason = ''
  isAlreadyFictive = false
  isAlreadyValid = false
  isEditFictiveReason = false

  historyLogTableData: ITableCell[][] = []

  historyLogParameters: IPickupRadiusValidationDetailParameters = {
    page: 1,
    perPage: 5,
  }

  historyLogHeaders = [
    this.headerCellMapper('Diubah Oleh', '50%'),
    this.headerCellMapper('Tanggal', '50%'),
  ]

  created(): void {
    this.fetchPickupRadiusValidationDetail(true)
  }

  get params(): IPickupRadiusValidationDetailParams {
    return {
      page: this.historyLogParameters.page,
      perPage: this.historyLogParameters.perPage,
    }
  }

  get isShowSubmitButton(): boolean {
    return (
      (this.validationValue === EnumValidation.valid && !this.isAlreadyValid) ||
      (this.validationValue === EnumValidation.fictive &&
        !this.isAlreadyFictive) ||
      (this.validationValue === EnumValidation.fictive &&
        this.isAlreadyFictive &&
        this.isEditFictiveReason)
    )
  }

  get isSubmitButtonDisabled(): boolean {
    return (
      this.validationValue === EnumValidation.fictive && !this.fictiveReason
    )
  }

  private fetchPickupRadiusValidationDetail(reset?: boolean): void {
    if (reset) {
      this.historyLogParameters.page = 1
    }
    this.controller.getPickupRadiusValidationDetail({
      pickupLogId: <number>(<unknown>this.$route.params.pickupLogId),
      params: this.params,
    })
  }

  private submitUpdateStatus(): void {
    const payload: UpdatePickupLogStatusPayload = {
      pickupLogId: <number>(<unknown>this.$route.params.pickupLogId),
      status: <number>(<unknown>parseInt(this.validationValue)),
      description: this.fictiveReason,
    }
    this.controller.updatePickupLogStatus(payload)
  }

  private onShowProofPhoto(index: number): void {
    this.showProofPhoto = true
    this.indexOfProofPhoto = index
  }

  private onChangeIsValid(value: EnumValidation): void {
    if (value === this.enumValidation.fictive) {
      this.isEditFictiveReason = true
    } else {
      this.isEditFictiveReason = false
      this.isAlreadyFictive = false
      this.fictiveReason = ''
    }
    this.validationValue = value
  }

  private setPickupStatus(status: number): void {
    switch (status) {
      case 1:
        this.validationValue = this.enumValidation.valid
        this.isAlreadyValid = true
        this.isAlreadyFictive = false
        break

      case 2:
        this.validationValue = this.enumValidation.fictive
        this.isAlreadyValid = false
        this.isAlreadyFictive = true
        this.isEditFictiveReason = false
        break

      default:
        this.validationValue = this.enumValidation.empty
        this.isAlreadyValid = false
        this.isAlreadyFictive = false
        break
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth
      },
    }
  }

  @Watch('controller.pickupRadiusValidationDetailData')
  private onPickupRadiusValidationDetailDataChange(
    value: PickupRadiusValidationDetail
  ): void {
    this.detailData = {
      courierName: value.courierName ? value.courierName : '-',
      courierPhoneNumber: value.courierPhoneNumber
        ? value.courierPhoneNumber
        : '-',
      courierTypeValid: value.courierTypeValid ? value.courierTypeValid : '-',
      courierLetterCode: value.courierLetterCode
        ? value.courierLetterCode
        : '-',
      shipmentId: value.shipmentId ? value.shipmentId : '-',
      createdAt: value.createdAt
        ? Utils.formatTimeZone(
            Utils.formatDateWithIDLocale(value.createdAt, 'DD MMM YYYY HH:mm Z')
          )
        : '-',
      actualPickupLocation: value.actualPickupLocation
        ? value.actualPickupLocation
        : '-',
      pickupLocation: value.pickupLocation ? value.pickupLocation : '-',
      address: value.address ? value.address : '-',
      pickupRadius: value.pickupRadius ? Math.round(value.pickupRadius) : 0,
    }
    this.fictiveReason = value.description ? value.description : ''
    this.proofPhoto = value.proofImages?.length ? value.proofImages : []
    this.setPickupStatus(<number>value.isPickupValid)
  }

  @Watch('controller.pickupHistoryLogData')
  private onPickupHistoryLogDataChange(data: HistoryLog[]): void {
    this.historyLogTableData = data.map(item => [
      this.tableCellMapper(item.updatedBy ? item.updatedBy : '-', '50%'),
      this.tableCellMapper(
        item.updatedDate
          ? Utils.formatTimeZone(
              Utils.formatDateWithIDLocale(
                item.updatedDate,
                'DD MMM YYYY HH:mm Z'
              )
            )
          : '-',
        '50%'
      ),
    ])
  }

  @Watch('controller.isUpdateStatusSuccess')
  private onIsUpdateStatusSuccessChange(value: boolean): void {
    if (value) {
      this.fetchPickupRadiusValidationDetail(true)
      this.$notify({
        title: 'Update Pickup Log Status Success',
        text: 'Pickup Log Status has been updated successfully',
        type: 'success',
        duration: 3000,
      })
    }
    this.controller.setIsUpdateStatusSuccess(false)
  }

  @Watch('fictiveReason')
  private onFictiveReasonChange(value: string): void {
    // remove script tag
    this.fictiveReason = Utils.removeScriptTags(value)

    // remove html tag
    this.fictiveReason = Utils.removeHTMLTags(this.fictiveReason)

    // remove special character
    this.fictiveReason = Utils.removeSpecialCharacters(this.fictiveReason)

  }
}
